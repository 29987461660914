/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Button, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-othbrv --style3 --full --parallax" name={"tpofztycegj"} parallax={true} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/8b345485cb0648e4a2e736eb2949ab36_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/8b345485cb0648e4a2e736eb2949ab36_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/8b345485cb0648e4a2e736eb2949ab36_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/8b345485cb0648e4a2e736eb2949ab36_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/8b345485cb0648e4a2e736eb2949ab36_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/8b345485cb0648e4a2e736eb2949ab36_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/8b345485cb0648e4a2e736eb2949ab36_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/8b345485cb0648e4a2e736eb2949ab36_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s3 pb--0 pl--40 pr--40 pt--40" anim={"5"} animS={"3"} style={{"maxWidth":774,"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Title className="title-box fs--62 title-box--invert" content={"<span style=\"color: rgb(24, 25, 29);\">Vyrábíme nábytek pro </span><span style=\"font-weight: bold; color: rgb(177, 37, 37);\">spokojenou</span><span style=\"color: rgb(24, 25, 29);\"> rodinu.</span><br>"}>
              </Title>

              <Subtitle className="subtitle-box mt--12" content={"... tu Vaši."}>
              </Subtitle>

              <Text className="text-box fs--18" style={{"maxWidth":595}} content={"Vyrábíme kvalitní kuchyně a nábytek na míru pro Vaši spokojenost. Realizujeme  po Praze, Středočeském kraji i okolí."}>
              </Text>

              <Button className="btn-box btn-box--right mt--16" use={"page"} href={"/produkty"} content={"Produkty a služby&nbsp; ➔"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"sluzby"} style={{"backgroundColor":"rgba(255, 255, 255, 1)"}}>
          
          <ColumnWrap className="column__flex --left el--2" columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--0 pt--0" anim={"2"} animS={"3"} style={{"maxWidth":"","backgroundColor":"rgba(248,248,248,1)"}}>
              
              <Image className="mb--0 mt--0 pt--0" src={"https://cdn.swbpg.com/t/10513/2f5597941e784076b925dbf21f59f377_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/10513/2f5597941e784076b925dbf21f59f377_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/2f5597941e784076b925dbf21f59f377_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/2f5597941e784076b925dbf21f59f377_s=860x_.jpg 860w"} position={null}>
              </Image>

              <Title className="title-box title-box--center fs--36" content={"Kuchyně"}>
              </Title>

              <Text className="text-box text-box--center fs--16 mt--08" style={{"maxWidth":495}} content={"Ergonomie, praktičnost a určitě propracovaný design, to jsou nutné atributy, které musí Vaše nová kuchyně splňovat. S tím vším Vám rádi pomůžeme.<br><br><br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn5 btn-box--center mt--20" use={"page"} href={"/kuchyne"} content={"Zobrazit"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--0 pt--0" anim={"2"} animS={"3"} style={{"maxWidth":"","backgroundColor":"rgba(248,248,248,1)"}}>
              
              <Image className="mb--0 mt--0 pt--0" src={"https://cdn.swbpg.com/t/10513/a14a664dcde64d4b97e854362c1af106_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/10513/a14a664dcde64d4b97e854362c1af106_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/a14a664dcde64d4b97e854362c1af106_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/a14a664dcde64d4b97e854362c1af106_s=860x_.jpg 860w"} position={null}>
              </Image>

              <Title className="title-box title-box--center fs--36" content={"Ložnice"}>
              </Title>

              <Text className="text-box text-box--center fs--16 mt--08" style={{"maxWidth":495}} content={"Vzhled i komfort, který si po celém dni zasloužíte. Vyberte si svou postel a noční stolky pro dokonalou relaxaci.<br><br><br><br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn5 btn-box--center mt--20" use={"page"} href={"/loznice"} content={"Zobrazit"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s3 mt--10 pb--0 pt--0" anim={"2"} animS={"3"} style={{"maxWidth":"","backgroundColor":"rgba(248,248,248,1)"}}>
              
              <Image className="mb--0 mt--0 pt--0" src={"https://cdn.swbpg.com/t/10513/3484f839d2354b568008be29b5b37f7e_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/10513/3484f839d2354b568008be29b5b37f7e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/3484f839d2354b568008be29b5b37f7e_s=660x_.jpg 660w"} position={null}>
              </Image>

              <Title className="title-box title-box--center fs--36" content={"Šatní skříně"}>
              </Title>

              <Text className="text-box text-box--center fs--16 mt--08" style={{"maxWidth":495}} content={"Šatna nebo šatní skříň? Ať jedno či druhé, rozhodně bude krásným designovým kouskem ve Vašem interiéru. Množství dekorů a úložných systémů Vám pomůže vše dokonale utřídit.<br><br><br><br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn5 btn-box--center mt--20" use={"page"} href={"/satni-skrine"} content={"Zobrazit"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s3 mt--10 pb--0 pt--0" anim={"2"} animS={"3"} style={{"maxWidth":"","backgroundColor":"rgba(248,248,248,1)"}}>
              
              <Image className="mb--0 mt--0 pt--0" src={"https://cdn.swbpg.com/t/10513/1327bb71175b49d6bb0dae8fba83ecb9_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/10513/1327bb71175b49d6bb0dae8fba83ecb9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/1327bb71175b49d6bb0dae8fba83ecb9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/1327bb71175b49d6bb0dae8fba83ecb9_s=860x_.jpg 860w"} position={null}>
              </Image>

              <Title className="title-box title-box--center fs--36" content={"Obývací pokoje"}>
              </Title>

              <Text className="text-box text-box--center fs--16 mt--08" style={{"maxWidth":495}} content={"Prezentujte svůj styl v obývacím pokoji od nás. Jednoduché, elegantní linie nebo rustikální vzhled? Inspirujte se u nás.<br><br><br><br><br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn5 btn-box--center mt--20" use={"page"} href={"/obyvaci-pokoje"} content={"Zobrazit"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="css-1k2mw0b --parallax pb--50 pt--50" name={"mz0v9ijxizf"} parallax={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/10513/d86e41d4d9c0472fb3ed96795bedca56_bri=85__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/10513/d86e41d4d9c0472fb3ed96795bedca56_bri=85__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/10513/d86e41d4d9c0472fb3ed96795bedca56_bri=85__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/10513/d86e41d4d9c0472fb3ed96795bedca56_bri=85__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/10513/d86e41d4d9c0472fb3ed96795bedca56_bri=85__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/10513/d86e41d4d9c0472fb3ed96795bedca56_bri=85__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/10513/d86e41d4d9c0472fb3ed96795bedca56_bri=85__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/10513/d86e41d4d9c0472fb3ed96795bedca56_bri=85__s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--2 pr--0 flex--bottom" animS={"3"} style={{"backgroundColor":"rgba(255,255,255,1)"}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim4 --anim-s3 --left pb--40 pl--40 pr--40 pt--40" anim={"4"} animS={"3"}>
              
              <Title className="title-box fs--62 title-box--invert" style={{"maxWidth":473}} content={"<span style=\"color: rgb(24, 25, 29);\">Jsme&nbsp;</span><span style=\"font-weight: bold; color: rgb(177, 37, 37);\">česká firma&nbsp;</span><span style=\"color: rgb(24, 25, 29);\">s tradicí.</span><br>"}>
              </Title>

              <Text className="text-box text-box--left" content={"Nábytek pro vás vyrábíme již více než 20 let. Sledujeme trendy v designu i vývoji materiálů, nabízíme vám vždy inovativní řešení. Spolupracujeme s předními dodavateli vnitřního vybavení nábytku a kování, spotřebičů i osvětlení pro váš pohodlnější život. \n"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left pb--0 pl--0 pr--0 pt--0" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
              
              <Image src={"https://cdn.swbpg.com/t/10513/ab42a4e16ae34a4691028a45bd493ccb_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/10513/ab42a4e16ae34a4691028a45bd493ccb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/ab42a4e16ae34a4691028a45bd493ccb_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/ab42a4e16ae34a4691028a45bd493ccb_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"54ten8dkh78"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Kdo jsme"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":844}} content={"Jsme český výrobce kuchyní a nábytku na míru. Kontaktujte nás pro realizaci Vašeho nového domova.&nbsp;"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/10513/a2a68bd23e4a44d2a061151536263597_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":156}} srcSet={"https://cdn.swbpg.com/t/10513/a2a68bd23e4a44d2a061151536263597_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/a2a68bd23e4a44d2a061151536263597_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/a2a68bd23e4a44d2a061151536263597_s=860x_.jpg 860w"}>
              </Image>

              <Title className="title-box" content={"Pavel Magda&nbsp;"}>
              </Title>

              <Text className="text-box mt--10" style={{"maxWidth":650}} content={"Specialista – zákaznický servis"}>
              </Text>

              <Text className="text-box mt--0" style={{"maxWidth":650}} content={" +420 777 206 285"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/10513/8be6492cc59e42ad8018c35ba4a36df3_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":129}} srcSet={"https://cdn.swbpg.com/t/10513/8be6492cc59e42ad8018c35ba4a36df3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/8be6492cc59e42ad8018c35ba4a36df3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/8be6492cc59e42ad8018c35ba4a36df3_s=860x_.jpg 860w"}>
              </Image>

              <Title className="title-box" content={"Miroslav Novák"}>
              </Title>

              <Text className="text-box mt--10" style={{"maxWidth":650}} content={"Oblastní zástupce"}>
              </Text>

              <Text className="text-box mt--0" style={{"maxWidth":650}} content={"+420 603 269 051"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/10513/a2a68bd23e4a44d2a061151536263597_s=660x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":156}} srcSet={"https://cdn.swbpg.com/t/10513/a2a68bd23e4a44d2a061151536263597_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10513/a2a68bd23e4a44d2a061151536263597_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10513/a2a68bd23e4a44d2a061151536263597_s=860x_.jpg 860w"}>
              </Image>

              <Title className="title-box" content={"Jitka Magdová"}>
              </Title>

              <Text className="text-box mt--10" style={{"maxWidth":650}} content={"Ekonomický úsek"}>
              </Text>

              <Text className="text-box mt--0" style={{"maxWidth":650}} content={"  +420 602 426 647"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-12vzq2n css-eivkvl --parallax pb--80 pt--80" name={"kontakt"} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f51f00a5e484493b8da4cc22926964b_bri=85__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f51f00a5e484493b8da4cc22926964b_bri=85__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f51f00a5e484493b8da4cc22926964b_bri=85__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f51f00a5e484493b8da4cc22926964b_bri=85__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f51f00a5e484493b8da4cc22926964b_bri=85__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f51f00a5e484493b8da4cc22926964b_bri=85__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f51f00a5e484493b8da4cc22926964b_bri=85__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/10513/0f51f00a5e484493b8da4cc22926964b_bri=85__s=3000x_.jpg);
    }
  
background-position: 50% 78%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: rgb(255, 255, 255);\">Pusťme se do práce.</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--center mt--16" use={"page"} href={"/kontakt"} content={"Kontaktovat&nbsp; ➔"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3 flex--center" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box w--500" content={"<span style=\"color: var(--color-dominant);\">Living 4 Handy</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":224}} content={"<span style=\"color: rgb(220, 221, 221);\">Výrobce kvalitních kuchyní a nábytku na míru pro Vaši spokojenost. Realizujeme  po Praze, Středočeském kraji i okolí.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--center" style={{"maxWidth":473}} content={"<span style=\"color: rgb(248, 248, 248);\">IČ 25731491<br>DIČ CZ25731491<br><br>Společnost je vedená u Městského soudu v Praze, spisová značka C 65152</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box w--500 lh--14" content={"<span style=\"color: var(--color-dominant);\">+420 777 206 285<br>info@living4handy.cz<br></span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: rgb(220, 221, 221);\">Living4Handy s.r.o.\n<br>Luženská 2801<br>&nbsp;Rakovník</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}